import React from 'react';
import './App.css';
import Whisper from './whisper';

function App() {
  return (
    <div className="App">
      <Whisper />
    </div>
  );
}

export default App;
